/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~ngx-toastr/toastr.css';
// @import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@1,500&family=Open+Sans:wght@400&display=swap');
@import 'animate.css';
/* width */

::-webkit-scrollbar {
    width: 4px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #c3c3c3;
    ;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

:root {
    --dark-red: #DD3D1D;
    --light-bg-red: #b73325;
    --brand-red: #B53218;
    --light-bg2: #FAFAFA;
    --font-family-sans-serif: "Mulish", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: "Overpass Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --main-red: #DD3D1D;
    --selected-red: #B43218;
    --hover-red: #B43218;
    // --light-bg: #EEE7E6;
    // --light-hover: #eae2e1;
    // --light-selected: #e0d3d1;
    --content-background: #fff4f4;
    --light-bg: #FFFFFF;
    --light-hover: rgb(242, 242, 242);
    --light-selected: rgb(239, 229, 255);
    --text-light-dark: rgb(107, 108, 123);
    --text-lightest: rgb(205, 205, 205);
    --text-full-dark: rgb(19, 9, 81);
    --table-header: #E6E9EE;
    --nu-primary-light: #ff8a8a;
    --nu-primary: #fc5d5d;
    --nu-primary-dark: #eb0e0e;
    --nu-white: #FFFFFF;
    --nu-greyLight-1: #f5e4e4;
    --nu-greyLight-2: #e7c8c8;
    --nu-greyLight-3: #e4bebe;
    --nu-greyDark: #cf9b9b;
    --nu-greyDarkExtra: #c14646;
    --moderate-grey-text: #989898;
    --deep-grey-text: #717171;
    --text-black: #333333;
    --deeper-grey-text: #505050;
}

.btn-black {
    background-color: var(--brand-red);
    border-color: var(--brand-red);
}

.btn-black :hover {
    background-color: var(--brand-red) !important;
    border-color: var(--brand-red) !important;
}

::ng-deep.tooltip.left {
    margin-left: -10px;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
